@import '../../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';

.control-pane {
  padding: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 20em;
}

.achievement-header h2 {
  font-weight: 300;
  color: #333 !important;
  text-align: left;
  padding-top: 7%;
  font-size: 1.75rem;
  margin-top: 10rem;
  margin-bottom: 2rem;
}

.control-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
  width: 100%;
  max-width: 1200px;
}

.achievement-col-xs-6.col-sm-6.col-lg-6.col-md-6 {
  flex: 0 0 31%;
  max-width: 31%;
  box-sizing: border-box;
}

.achievement-e-card-resize-container {
  width: 100%;
  height: 500px;
  position: relative;
  perspective: 1000px;
}

.e-card {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.e-card-content {
  padding: 10px !important;
  font-size: 12px !important;
  color: black !important;
  padding-bottom: 20px !important;
}


.show-card #card_revealed {
  display: none;
  animation: fallFromTop 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.show-card #card_reveal {
  display: block;
}

.hide-card #card_revealed {
  display: block;
  animation: riseFromBottom 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.hide-card #card_reveal {
  display: none;
}

@keyframes riseFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fallFromTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}


.e-card-header {
  padding: 15px;
  background-color: #1E3E62;
}

.e-card-header-title {
  color: #ffffff !important;
}

.e-card-actions {
  margin-top: 0px;
  text-align: left;
}

.e-icons {
  cursor: pointer;
  color: white;
}

.img-responsive {
  height: 200px;
  width: 100%;
}

.close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}


/* スマホ */
@media (max-width: 768px) {
  .achievement-col-xs-6.col-sm-6.col-lg-6.col-md-6 {
    flex: 0 0 47%;
    max-width: 47%;
  }
}

@media (max-width: 576px) {
  .achievement-col-xs-6.col-sm-6.col-lg-6.col-md-6 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}
