/* フッター */
.footer {
  background-color: #282c34;
  color: #7f9baa;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #444;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.footer-content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-info {
  margin-bottom: 20px;
}

.footer-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.footer-address {
  font-size: 0.9em;
  line-height: 1.6;
}

.footer-nav {
  margin-bottom: 20px;
  width: 100%;
}

.footer-nav-container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.footer-nav-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.footer-nav-list {
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column; 
}

.footer-nav-item {
  margin: 10px 0; 
}

.footer-nav-link {
  color: #7f9baa;
  text-decoration: none;
  font-size: 0.9em;
  transition: color 0.3s, text-decoration 0.3s;
}

.footer-nav-link:hover {
  color: #ffffff;
  text-decoration: underline;
}

.footer-nav-subtext {
  font-size: 0.8em;
  color: #aaaaaa;
}

.footer-copy {
  font-size: 1.0em;
  margin-top: 20px;
}

.footer-address a {
  color: #7f9baa;
  text-decoration: none;
  font-size: 0.9em;
  transition: color 0.3s, text-decoration 0.3s;
}

.footer-address a:hover {
  color: #ffffff;
  text-decoration: underline;
}

body, html {
  overflow-x: hidden;
}
