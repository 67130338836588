.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20000;
  }
  
  .image-modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
  }
  
  .image-modal-image {
    width: 100%;
    height: auto;
  }
  
  .close-modal-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
  }
  