/*代表挨拶*/
.greeting-page {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 100px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  height: auto;
  align-items: center;
}


.greeting {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 150px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}
.ceo-section {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.ceo-photo {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-right: 20px;
  animation: fadeIn 1.5s ease-in-out;
  margin-bottom: 6rem;
}


@keyframes slideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.greeting h1 {
  color: #003e88;
  text-align: left;
  font-size: 2rem;
  animation: slideUp 1s ease-in-out;
  font-weight: 500; 
}


.greeting h2 {
  color: #333;
  font-size: 1.75rem;
  margin-top: 30px;
  text-align: left;
  margin-bottom: 35px;
  font-weight: 300; 
}

.greeting p {
  color: #666;
  line-height: 1.8;
  font-size: 1rem;
  margin: 20px 0;
  text-align: left;
}


/*署名*/
.signature {
  max-width: 360px;
  margin-top: 35px;
  display: block;
  margin-left: 0px;
  animation: fadeIn 1.5s ease-in-out;
}

/*powered by*/
.powered-by {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0;
  margin-left: 400px;
  animation: fadeInUp 1s ease-in-out;
}

.powered-by span {
  margin-right: 5px;
  margin-top: 0px;
  height: 10px;
  font-size: 1rem;
  color: #666;
}

.powered-by .logo {
  width: 30px;
  margin-top: 10px;
  transition: transform 0.3s;
}

.powered-by .logo:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .company-page {
    padding: 15px;
    margin-top: 10%;
  }

  .greeting h1 {
    font-size: 1.5rem;
    margin-top: 20%;
  }

  .greeting h2,
  .company-info h2 {
    font-size: 1.5rem;
  }

  .greeting p,
  .company-info table {
    font-size: 0.9rem;
  }

  .powered-by {
    justify-content: left;
    align-items: left;
    margin: 0px 0;
    animation: fadeInUp 1s ease-in-out;
  }

  .powered-by span {
    margin-right: 5px;
    margin-top: 0px;
    font-size: 1rem;
    color: #666;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInUp {
  0% { transform: translateY(20px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

