.aws-partner-announcement-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    background-size: cover;
    background-position: center;
    color: #219C90;
    text-align: center;
    min-height: 150vh;
}

.announcement-header {
    margin-bottom: 20px;
    animation: fadeInDown 1s ease-out;
}

.announcement-header h1 {
    font-size: 1.8rem;
    margin: 0;
    margin-bottom: 50px;
    font-weight: 400;
}
.announcement-image {
    max-width: 100%;
    height: auto;
    margin-top: 100px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.announcement-content, .announcement-section {
    max-width: 900px;
    animation: fadeInUp 1s ease-out;
    margin-bottom: 40px;
    text-align: left;
}

.announcement-section h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    font-weight: 400;
}

.announcement-content p, .announcement-section p {
    font-size: 1.0rem;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #3C3D37;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
