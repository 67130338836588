/*ヘッダー*/
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.5);
  color: #006eb9;
  z-index: 10000;
  overflow-x: hidden;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 4.0em;
  width: auto;
}

.logo-link {
  text-decoration: none;
  color: inherit;
}


.logo-text {
  margin-left: 10px;
}

.logo-subtext {
  display: block;
  margin-left: 40px;
  margin-top: 0px;
  font-size: 1.2rem;
  color: #ccc;
}

.logo h1 {
  margin-left: 20px;
  font-size: 1.8em;
  font-weight: 500;
}

nav ul {
  list-style-type: none;
  margin: 0;
  margin-top: 3rem;
  padding: 0;
  display: flex;
}

nav li {
  margin: 0 15px;
}

.nav-menu li.contact-link {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 18px;
  padding: 5px;
  z-index: 10001;
}

.nav-menu li.contact-link a {
  position: relative;
  color: #117554;
  display: flex;
  align-items: center;
  border: 1px solid #117554;
  padding: 10px 20px;
  overflow: hidden;
  transition: color 0.4s ease, background-color 0.4s ease;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, white 50%, #117554 50%);
  background-position: left;
}
.nav-menu li.contact-link a:hover {
  color: white;
  background-position: right; 
}

.nav-menu li.contact-link a.active-link  {
  font-weight: 800;
}

.nav-menu li.contact-link a .fas {
  margin-right: 8px;
}

.nav-subtext {
  display: block;
  font-size: 0.75em;
  color: #ccc;
}

.header-nav a {
  color: #000000;
  text-decoration: none;
  font-size: 1.0rem;
  position: relative;
}

.header-nav a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 1px;
  background-color: #006eb9;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.4s ease;
}

.header-nav a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

body {
  margin-top: 60px;
}

.header-nav a.active-link  {
  font-weight: bold;
  color: #006eb9;
}

.nav-toggle {
  display: none;
}

/* スマホ */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .logo {
    margin-bottom: 0px;
  }

  .logo h1 {
    font-family: 'Noto Sans JP', sans-serif;
    margin-left: 10px;
    font-size: 1.5rem;
  }

  .nav-toggle {
    display: block;
    font-size: 2rem;
    cursor: pointer;
    z-index: 10002;
    position: absolute;
    top: 15px;
    right: 20px;
    color: #000;
  }

  .nav-menu {
    display: none;
    width: 100%;
    padding: 5px;
    background-color: white;
    box-shadow: 0 4px 2px -2px #B7CADB;
    overflow-x: hidden;
  }

  .nav-menu.open {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    z-index: 1000;
    padding-top: 60px;
    box-sizing: border-box;
  }

  nav ul {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  nav li {
    margin: 5px 0;
  }

  nav a {
    display: block;
    padding: 5px;
    text-decoration: none;
    font-size: 0.8rem;
  }

  nav a:hover {
    background-color: #f0f0f0;
    text-decoration: none;
  }

  nav a.active-link {
    font-weight: bold;
  }

  .nav-menu li.contact-link {
    position: static;
    width: 60%;
    font-size: 16px;
    margin-top: 10px;
    background: transparent;
    padding: 0;
  }
}

@media (min-width: 769px) {
  .nav-toggle {
    display: none;
  }

  .header-container {
    flex-direction: row;
  }
}
