/* プライバシーポリシー */
.privacy-policy {
  padding: 40px 20px;
  max-width: 50%;
  margin: auto;
  height: auto;
}

.privacy-policy h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
  margin-top: 5rem;
  color: #2c3e50;
  text-align: center;
  font-weight: 300;
  border-bottom: 2px solid #7f9baa;
  padding-bottom: 10px;
}

.privacy-policy pre {
  white-space: pre-wrap;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.8;
  color: #333;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
}

.privacy-policy pre code {
  font-family: 'Courier New', monospace;
  background-color: #f4f4f4;
  padding: 5px;
  border-radius: 4px;
}

.privacy-policy p {
  margin-bottom: 1.5rem;
  color: #666;
  line-height: 1.6;
}

.privacy-policy ol, .privacy-policy ul {
  margin-left: 20px;
  margin-bottom: 1.5rem;
}

.privacy-policy ol li, .privacy-policy ul li {
  margin-bottom: 1rem;
  color: #555;
}

.privacy-policy a {
  color: #3498db;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .privacy-policy {
    padding: 10px;
    font-size: 0.9rem;
    width:90%;
    max-width: 90%;
    height:auto;
    align-content: center;
    align-items: center;
    align-self: center;
  }

  .privacy-policy h1 {
    font-size: 1.5rem;
    margin-top: 10rem;
  }

  .privacy-policy pre {
    font-size: 0.9rem;
  }
}
