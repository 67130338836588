.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  width: 100%;
  max-width: 1100px;
  height: 70%;
  position: relative;
  border-radius: 16px;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
  z-index: 2; 
}


.floating-shapes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}

.shape {
  position: absolute;
  background-color: rgba(0, 200, 255, 0.6);
  animation: float 8s infinite ease-in-out;
  opacity: 0.8;
  z-index: 1;
}

.diamond {
  width: 30px;
  height: 30px;
  background-color: rgba(0, 255, 127, 0.6);
  transform: rotate(45deg);
  top: 30%;
  left: 50%;
}

.circle {
  width: 35px;
  height: 35px;
  background-color: rgba(0, 200, 255, 0.6);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 70%;
  animation: float 10s infinite ease-in-out;
  z-index: 1;
}

@keyframes float {
  0% {
    transform: translateY(0) rotate(45deg);
  }
  50% {
    transform: translateY(-20px) rotate(45deg);
  }
  100% {
    transform: translateY(0) rotate(45deg);
  }
}


.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #333;
}

.popup-content h2 {
  font-size: 1.8rem;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.preparing-text {
  font-size: 1.2rem;
  color: #222;
  margin: 0;
  line-height: 0.5;
}

.preparing-text:first-of-type {
  margin-top: 20% !important;
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
