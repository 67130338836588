/*業務案内*/
.business-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  height: 100vh;
}
.coming-soon-message h1{
  margin-top: 10rem;
  text-align: center;
}

.coming-soon-message p{
  margin-top: 2rem;
  text-align: center;
}