/* 星空 - Fullscreen */
.starry-sky-fullscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100%;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  overflow: hidden;
}

.starry-sky-fullscreen canvas {
  width: 100%;
  height: 100vh;
  position: fixed;
}

body {
  height: 200%;
  margin: 0;
  padding: 0;
}


.itp-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  opacity: 0;
  transition: opacity 1s ease-in;
}

.itp-logo.show-logo {
  opacity: 1;
}

@media (max-width: 768px) {
  .itp-logo {
    width: 100px;
  }
}