/* 会社案内 */
.company-page {
  padding: 20px;
  max-width: 1000px;
  margin: 100px auto 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  height:auto;
}

@keyframes fadeInUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.company-info {
  border-radius: 12px;
  padding: 25px;
  background-color: #fff;
  animation: fadeInUp 0.8s ease-in-out;
  margin-bottom: 40px;
}

.company-info h2 {
  color: #333;
  font-size: 1.75rem;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-weight: 300; 
}

.company-info h3 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #003e88;
  font-weight: 300; 
}

/* 会社概要 */
.overview-list {
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  color: #333;
}

.key-value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin: 0;
}

.key {
  font-weight: bold;
  color: #777;
  text-align: center;
  font-size: 0.8rem;
}

.value {
  flex: 1;
  text-align: right;
  margin-left: 10%;
  color: #000;
  font-size: 0.8rem;
}

.company-info .company-overview {
  padding: 10px;
}

/* 沿革 */
.company-history {
  margin-top: 20px;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.company-history h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.history-list .e-list-item {
  color: #000;
  font-size: 0.9rem;
  padding: 12px;
  border-bottom: 1px solid #ddd;
  position: relative;
  padding-left: 40px;
}

.history-list .e-list-item:before {
  content: '•';
  color: #1976d2;
  font-size: 1.5rem;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.history-list .e-list-item:last-child {
  border-bottom: none;
}

/* アクセス */
.access-container {
  position: relative;
  z-index: 1; 
  margin-bottom: 20rem;
}

.access-container h2{
  font-size: 1.5rem;
  font-weight: 300; 
}

.map-container {
  position: relative;
  align-items: center;
  margin-top: 50;
  left: 0;
  right: 0;
  width: 100%;
  height: 400px;
  z-index: 1; 
}

.dashboard-container {
  position: relative;
  top: 5rem;
}

/*カード内*/
.card-map-container{
  position: relative;
  align-items: center;
  margin-top: 50;
  margin-bottom: 5px;
  left: 0;
  right: 0;
  width: 'auto';
  z-index: 1; 
}

.access-nagoya, .access-fukuoka {
  padding: 20px;
  background-color: #fff;
  margin: 20px auto;
  max-width: 500px;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.access-nagoya::before, .access-fukuoka::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 60%;
  left: 0;
  width: 6px;
  transition: background-color 0.3s ease;
}

.access-nagoya::before {
  background-color: #4CCD99;
}

.access-fukuoka::before {
  background-color: #83B4FF;
}


.map-container {
  margin-top: 20px;
  text-align: center;
}

.map-image {
  margin-top: 7%;
  width: 60%;
  height: auto;
  text-align: center;
}

/*所在地・アクセス*/
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

th {
  width: 30%;
}

ul {
  padding-left: 20px;
  margin: 0;
}
/* 画像 */
.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.gallery-image {
  width: 23%;
  height: auto;
  object-fit: cover;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.hidden {
  opacity: 0;
  transform: translateY(240px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-up {
  opacity: 1;
  transform: translateY(0);
}


/* スマホ */
@media (max-width: 768px) {
  .access-container h2{
    text-align: left;
    padding-left: 5%;
    padding-top: 20px;
    font-size: 1.6rem;
  }
  .dashboard-container {
    position: relative;
    padding-left: 0px;
  }
  .access-nagoya, .access-fukuoka {
    max-width: 100%;
    padding: 15px;
    margin: 20px 0;
    margin-bottom: 5em;
  }
}

@keyframes slideUpFade {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.company-history {
  animation: slideUpFade 0.8s ease-in-out;
}

@media (max-width: 768px) {
  .company-page {
    padding: 15px;
    margin-top: 50px;
  }

  .company-info h2 {
    font-size: 1.5rem;
  }

  .e-grid .e-headercell, .e-grid .e-rowcell {
    padding: 10px;
  }

  .image-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .gallery-image {
    width: 60%;
    height: auto;
  }
}
