/* 採用情報 */
.recruitment-page {
  padding: 100px;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: auto;
}

.message {
  border-radius: 8px;
  padding: 20px;
  width: 60%;
}

.content-boxes {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.box {
  width: 45%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #333;
  text-align: center;
  text-decoration: none;
  color: black;
  position: relative;
  overflow: hidden;
  background-color: white;
  background-image: none;
  transition: background-size 0.7s ease,  color 0.7s ease;
}

.box-job:hover,
.box-process:hover {
  background-size: cover;
  background-position: center;
}

.box-process:hover {
  background-image: url('./recruit1.jpg');
}

.box-job:hover {
  background-image: url('./recruit2.jpg');
}

.box-job:hover .box-content,
.box-process:hover .box-content {
  color: #003e88;
}

.process h2, .job-info h2 {
  border-bottom: 2px solid #003e88;
}

.message p, .process p, .job-info p {
  line-height: 1.8;
  color: #333;
  font-size: 1rem;
  font-weight: 300; 
}


.process-info, .job-info {
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  margin: 20px 0;
  max-width: 60%;
  margin-left:10%;
  height:auto;
}

.message h2, .process-info h2, .job-info h2 {
  font-size: 1.8rem;
  margin-top: 6rem;
  margin-bottom: 20px;
  color: #003e88;
  border-bottom: 2px solid #003e88;
  padding-bottom: 10px;
  font-weight: 300;
}

.box .arrow-icon {
  font-size: 30px;
  background-color: #ffffff;
  color:#000;
  border: 1px solid #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 20px;
  transition: background-color 0.3s ease;
}

.box:hover .arrow-icon {
  background-color: #000;
  color:#ffffff;
}

.return-button {
  position: absolute;
  top: 150px;
  left: 100px;
  background-color: #ffffff;
  color:#000;
  border: 1px solid #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.return-button:hover {
  background-color: #000;
  color:#ffffff;
}

/* 流れリスト */
.process-info ol {
  margin-left: 20px;
  padding-left: 10px;
  list-style-type: decimal;
}

.process-info li {
  margin-bottom: 15px;
  padding-left: 10px;
}

.process-info strong {
  color: #333;
  font-weight: 600;
}

/* 採用情報　リスト */
.job-info ul {
  list-style-type: disc;
  margin-left: 20px;
  padding-left: 10px;
}

.job-info ul ul {
  list-style-type: circle;
  margin-left: 20px;
}

.job-info li {
  margin-bottom: 10px;
  font-size: 1rem;
}

.job-info h3 {
  color: #333;
  margin-top: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  font-size: 1.2rem;
  font-weight: 300; 
}

@media (max-width: 768px) {
  .recruitment-page {
    padding: 20px;
  }
  .message {
    padding: 0px;
    width: 100%;
  }
  
  .content-boxes {
    width: 100%;
  }
  
  .box {
    width: 100%;
    height: 300px;
    font-size: 0.7rem;
    font-weight: 100;
    position: relative;
    overflow: hidden;
  }
  .process-info, .job-info {
    padding: 20px;
    margin: 20px 0;
    max-width: 90%;
    margin-left:5%;
  }

  .message h2, .process-info h2, .job-info h2 {
    font-size: 1.0rem;
    margin-top: 10rem;
  }

  .process-info h3 {
    font-size: 1.0rem;
    margin-top: 3rem;
  }

  .return-button {
    position: absolute;
    top: 7.5rem;
    left: 1.5rem;
  }
  .box .arrow-icon {
    font-size: 15px;
    width: 30px;
    height: 30px;
  }
  .job-info li, .process-info li, .process-info p  {
    margin-bottom: 10px;
    font-size: 0.9rem;
  }
}
