.hp-renewal-announcement-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-position: center;
    color: #219C90;
    text-align: center;
    height: 100vh;
}

.announcement-header {
    margin-top: 10rem;
    margin-bottom: 20px;
    animation: fadeInDown 1s ease-out;
}

.announcement-header h1 {
    font-size: 1.8rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 50px;
}
.announcement-content, .announcement-section {
    max-width: 900px;
    animation: fadeInUp 1s ease-out;
    margin-bottom: 40px;
    text-align: left;
}

.announcement-content p, .announcement-section p {
    font-size: 1.0rem;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #3C3D37;
}

.old-hp-link {
    color:#3C3D37;
    transition: all 0.3s ease;
}

.old-hp-link:hover {
    color: #219C90;
    transform: translateY(-2px);
}

.return-button {
    position: absolute;
    top: 10rem;
    left: 100px;
    background-color: #ffffff;
    color:#000;
    border: 1px solid #333;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .return-button:hover {
    background-color: #000;
    color:#ffffff;
  }
  
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* スマホ */
@media (max-width: 768px) {
    .announcement-header h1 {
        font-size: 1.5rem;
        margin: 0;
        margin-top: 3rem;
        margin-bottom: 30px;
    }
    .announcement-content, .announcement-section {
        max-width: 80%;
    }

    .announcement-content p, .announcement-section p {
        font-size: 0.9rem;
        line-height: 1.8;
        margin-bottom: 20px;
        color: #3C3D37;
    }
}
