@import '../../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';

.control-pane {
  padding: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 20em;
}

.service-header h2{
  font-weight: 300;
  color: #333 !important;
  text-align: left;
  padding-top: 7%;
  font-size: 1.75rem;
  margin-top: 10rem;
  margin-bottom: 2rem;
}
.control-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
  width: 90%;
}

.service-col-xs-6.col-sm-6.col-lg-6.col-md-6 {
  flex: 0 0 33%;
  max-width: 33%;
  box-sizing: border-box;
}

.e-card-resize-container {
  width: 100%;
  height: 450px;
  position: relative;
  perspective: 1000px;
}
  
  .e-card {
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  .e-card-content {
    padding: 5px !important;
    font-size: 11px!important;
    color: black !important;
    padding-bottom: 20px !important;
  }
  
  #card_revealed,
  #card_reveal {
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #card_revealed {
    display: block;
  }
  
  #card_reveal {
    display: none;
    animation: riseFromBottom 0.5s ease-in-out forwards;
  }
  
  .show-card #card_revealed {
    display: none;
  }
  
  .show-card #card_reveal {
    display: block;
  }

  .e-card-header {
    padding: 15px;
    background-color: #243642;
  }

  .e-card-header-title {
    color: #ffffff !important;
  }

  .e-card-actions {
    margin-top: 0px;
    text-align: left;
  }

  .e-icons {
    cursor: pointer;
    color: white;
  }

  .img-responsive{
    height:200px;
    width: 100%;
  }
  .close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }

  @keyframes riseFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    .service-col-xs-6.col-sm-6.col-lg-6.col-md-6 {
      flex: 0 0 47%;
      max-width: 47%;
    }
  }
  
  @media (max-width: 576px) {
    .service-col-xs-6.col-sm-6.col-lg-6.col-md-6 {
      flex: 0 0 80%;
      max-width: 80%;
    }
  }