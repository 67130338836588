/* 星空*/
.starry-sky-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 50%;
}

.starry-sky {
  width: 200px;
  height: 120px;
  background-color: black;
  border-radius: 10px;
  margin-left: 10px;
}

.powered-by {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 65%;
  font-size: 12px;
  color: gray;
}

.powered-by .logo {
  height: 20px;
  margin-left: 5px;
}

/* スマホ */
@media (max-width: 768px) {
  .starry-sky-container {
    display: flex; 
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
  
  .starry-sky {
    width: 200px;
    height: 120px;
    background-color: black;
    border-radius: 10px;
    margin-left: 0;
  }

  .powered-by {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 160px;
    font-size: 12px;
    color: gray;
  }
}
