/* ホーム */
.home-container {
    position: relative;
    background-color: #ffffff;
    margin: 0 auto;
    height: auto;
}

/* 画像 */
.image-slider1 {
    position: relative; 
    width: 60%;
    height: auto;
    top: 200px;
    margin: 0 auto;
    overflow: hidden;
    z-index: 100; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-image {
    align-items: center;
    width: 100%;
    height: auto;
    opacity: 1;
    margin: 0;
    transition: opacity 1s ease-in-out;
}

/*画像2 案件実績*/
.image-slider2 {
    position: relative; 
    width: 35%;
    height: auto;
    top: 30px;
    margin: 0 auto;
    margin-bottom: 50px;
    overflow: hidden;
    z-index: 100; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-image2 {
    width: 80%; /* Adjusted width for better centering */
    height: auto;
    border: 0.5px solid #ddd;
}

.image2-wrapper {
    padding: 40px 20px;
    margin-top: 100px;
    width: 100%;
    height:auto;
    margin-left: auto;
    margin-right: auto;
    clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 100%);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
}

.image2-wrapper h2 {
    text-align: center;
    font-size: 2.5rem;
}

.image2-wrapper h3 {
    text-align: center;
    font-size: 1.2rem;
}

.slider-item-wrapper {
    padding: 10px 0;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
}

.info-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-weight: 300;
}

.info-subtitle {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-weight: 300;
    background-color: #141E46;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
}

.info-subtitle2 {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-weight: 300;
    background-color: #1679AB;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
}

.button-container {
    margin-top: 0px; 
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    width: 100%;
}

/*リストビュー*/
.home-listview-container {
    justify-content: center ;
    align-items: center;
    width: 65% ;
    margin: 0 auto;
    margin-top: 200px ;
    height: auto ;
    overflow: auto 
}

.home-listview-container h2{
    align-items: center;
    text-align: center;
    font-size: 2.5rem;
}

.home-listview-container h3 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.2rem;
}

.home-list-item-card {
    border-radius: 0px;
    padding: 5px;
    overflow: auto; 
}

.item-date {
    font-weight: bold;
    margin-bottom: 3px;
    color:#006eb9;
}

.item-content {
    font-size: 14px;
}

.item-1, .item-2, .item-3, .item-4, .item-5, .item-6 {
    border-left: 4px solid #006eb9;
}

/* リストビューリンク */
.clickable-item {
    cursor: pointer;
}

.clickable-item p {
    color: black;
    transition: color 0.3s ease; 
}

.clickable-item .item-date{
    color: #006eb9;
}

.clickable-item:hover p {
    text-decoration: underline;
}

.arrow-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    background-color: #006eb9;
    border-radius: 50%;
    position: absolute;
    right: 5%;
    bottom:20%;
    transition: background-color 0.3s ease;
}

.arrow-icon {
    color: #fff;
    font-size: 14px;
    line-height: 1;
}

.clickable-item:hover .arrow-circle {
    background-color: #1E3E62; 
}

.e-list-item {
    padding: 5px 0px;
    border-bottom: 1px solid #ddd;
    position: relative;
    text-align: left;
    font-size: 12px;
}

/* 会社案内｜業務案内｜ 採用案内 */
.three-squares-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 400px;
    margin-top: 5rem;
}

.square {
    width: 33.33%;
    height: 30em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 0.8px solid black;
}

.content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.content-right {
    display: flex;
    flex-direction: column;
}

.square-title {
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 1rem;
    margin-right: 10px;
    margin-bottom: 10px;
    flex-shrink: 0;
}

.square-image {
    width: 60%;
    height: 300px;
    object-fit: cover;
    margin-bottom: 10px;
    margin-left: 20%;
}

.square-description {
    font-size: 0.8rem;
    margin-bottom: 10px;
}

.read-more-btn {
    position: relative;
    align-content: center;
    background-color: white;
    color: black;
    border: 1px solid #555;
    padding: 10px 20px;
    font-size: 0.7rem;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.4s ease;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, black 50%, white 50%);
    background-position: right;
    width: 8rem;
    margin-right: 0.8rem;
    margin-left: auto;
    border-radius: 20px;
}

.read-more-btn:hover {
    color: white;
    background-position: left;
}

/*自社サービス*/
.services-wrapper {
    padding: 90px;
    background-color: #f9f9f9;
    clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 100%);
  }

  .services-wrapper h2{
    margin-top: 60px;
    text-align: center;
    font-size: 2.5rem;
  }
  .services-wrapper h3 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.2rem;
}
  .services-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .service-item {
    flex: 1 1 15%;
    margin: 50px;
    padding: 10px;
    color: #219C90;
    border: none;
    text-align: center;
    background-color: inherit;
    opacity: 0;
}

.service-item.in-view {
    opacity: 0;
    transform: translateY(0);
    animation: fadeInUp 2.0s ease-out forwards;
}

.service-item:nth-child(1).in-view {
    animation-delay: 0.1s;
}

.service-item:nth-child(2).in-view {
    animation-delay: 0.3s;
}

.service-item:nth-child(3).in-view {
    animation-delay: 0.5s;
}

.service-item:nth-child(4).in-view {
    animation-delay: 0.7s;
}

.service-item:hover {
    transform: scale(1.05);
}
.service-item h3 {
    margin-top: 10px;
    font-size: 1.1em;
  }
  
.service-item p {
    font-size: 0.8em;
    color: #666;
  }

/*お問い合わせ*/
.contact-wrapper {
    background-color: #eef3f6;
    clip-path: polygon(1% 8%, 100% 0%, 100% 100%, 0% 100%);
    padding: 20px;
    margin-top: 10px;
    width: 100%; 
}

.contact-box {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 80px;
    margin: 20px auto;
    margin-top: 100px;
    margin-bottom: 200px;
    text-align: center;
    max-width: 600px;
    justify-content: center;
}

.contact-wrapper  h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-top: 150px;
}

.contact-box h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-decoration: underline;
    text-decoration-color: #006eb9; 
    text-underline-offset: 10px;
}

.contact-box p {
    font-size: 0.9rem;
    margin-bottom: 1rem !important;
    line-height: 1.8;
}

.contact-box a {
    color: inherit;
    text-decoration: none !important;
    font-size: 0.9em;
  }

.contact-box a:hover {
    color: #0B2F9F;
    text-decoration: underline;
}

/*View More ボタン*/
.image2-wrapper button,
.services-wrapper button,
.contact-box button {
    position: relative;
    align-content: center;
    background-color: white;
    color: black;
    border: 1px solid #555;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.4s ease;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, black 50%, white 50%);
    background-position: right;
}

.image2-wrapper button:hover,
.services-wrapper button:hover,
.contact-box button:hover {
    color: white;
    background-position: left;
}


/* スマホ */
@media (max-width: 768px) {
    .home-container {
        height: auto;
    }
    .image-slider1 {
        width: 100%;
        height: 300px;
        top: 100px;
        margin: 0;
        margin-top: 5rem;
    }
    .slider-image {
        width: 100%;
        height: 100%;
        margin: 0;
    }
    .image-slider2 {
        width: 100%;
        height: auto;
        top: 30px;
        margin: 0;
        margin-bottom: 4rem;
    }
    
    .slider-image2 {
        width: 100%;
        height: 100%;
        margin: 0;
    }
    .three-squares-container {
        flex-direction: column;
        height: auto;
    }

    .square {
        width: 100%;
        margin-bottom: 20px;
    }

    .info-title {
        font-size: 0.8rem;
    }
      
    .info-subtitle {
        font-size: 0.8rem;
    }
    .info-label,
    .info-value {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px;
        text-align: left;
    }
      
    .info-label {
        background-color: #ddd;
        font-size: 0.9rem;
    }
      
    .info-value {
        background-color: #ffffff;
        font-size: 0.9rem;
        justify-content: left;
    }
    .home-listview-container {
        width: 100%;
        margin: 0 auto;
        margin-top: 100px;
        height: auto;
        overflow: auto;
    }
    .image2-wrapper button{
        padding: 10px 20px;
        margin-top: 0;
    }
    .services-wrapper h2{
        margin-top: 40px;
        font-size: 2rem;
      }
      .services-wrapper h3 {
        margin-bottom: 10px;
    }
    .service-item{
    margin: 50px;
    padding: 10px;
    color: #219C90;
    border: none;
    opacity: 0;
    margin-bottom: 0px;
    }
    .services-wrapper button {
        position: relative;
        padding: 10px 20px;
        font-size: 1rem;
        margin-bottom: 50px;
    }
}


/* アニメーション */
@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.fade-in-up {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s ease-out;
}

.in-view {
    opacity: 1;
    transform: translateY(0);
    animation: fadeInUp 0.8s ease-out forwards;
}
@keyframes fadeInUp {
    from {
        transform: translateY(50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
